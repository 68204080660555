<template id="inventario">
<div class="page-inventario">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Cargar Inventario a Sucursal" class="elevation-1 px-5 py-3">

          <v-card-text>
              <v-alert color="warning"><v-icon>info</v-icon>Al cargar el inventario, se guardará la existencia de cada artículo contenido en el archivo excel
                quedando como la existencia actual. Los artículos que no se especifiquen en el excel no se modificará su existencia.</v-alert>
          </v-card-text>
          <v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                    <exportar-excel :data="camposLayoutInventario" :exportFields="encabezadoLayoutInventario" name="Plantilla_Inventario.xlsx">
                    <v-btn fab dark x-small color="indigo darken-2" title="Plantilla Inventario" v-tippy>
                        <v-icon dark>receipt</v-icon>
                    </v-btn>
                    </exportar-excel>
                    &nbsp;
                    <upload-excel-component title="Importar Inventario" idInput="inventarioKey" :on-success="handleSuccessInventario" :before-upload="beforeUpload" />
            </v-card-actions>

            <div id="datagrid" v-on:keyup.enter="items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>

                <template slot="actions" slot-scope="props">
                <v-btn x-small fab dark color="green" @click.native="descargarAdjunto(props.row)" title="Descargar" v-tippy>
                      <v-icon>file_download</v-icon>
                    </v-btn>&nbsp;
                    <!--v-btn x-small fab dark color="pink" @click.native="deleteRegistro(props.row._id, props.row._rev )" title="Eliminar Registro" v-tippy>
                      <v-icon>delete</v-icon>
                    </v-btn-->
                </template>
              </v-client-table>
              <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>

        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <!-- MODAL ARTICULOS -->
  <v-dialog v-model="modal_detalle" max-width="60%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">add</v-icon>&nbsp;
          <span class="subheading">
            <strong>Detalle de Artículos</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal_detalle = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-row>
            <v-col>
              <v-client-table ref="vuetablearticulos" :data="lote_detalle" :columns="columns_detalle" :options="options_detalle" class="elevation-2 pa-2">
                <template slot="id_sucursal" slot-scope="props">
                  {{get_sucursal(props.row.id_sucursal)}}
                </template>

                <template slot="fecha_caducidad" slot-scope="props">
                  {{props.row.fecha_caducidad.toString().substring(0,10)}}
                </template>

                <template slot="fecha_entrada" slot-scope="props">
                  {{props.row.fecha_entrada.toString().substring(0,10)}}
                </template>

                <template slot="cantidad" slot-scope="props">
                  <div style="text-align: center;">
                    {{props.row.cantidad}}
                  </div>
                </template>

                <template slot="precio_compra" slot-scope="props">
                  <div style="text-align: center;">
                    ${{props.row.precio_compra}}
                  </div>
                </template>

                <template slot="actual" slot-scope="props">
                  <div style="text-align: center;">
                    {{parseFloat(props.row.actual).toFixed(2)}}
                  </div>
                </template>

                <template slot="comprometido" slot-scope="props">
                  <div style="text-align: center;">
                    {{parseFloat(props.row.comprometido).toFixed(2)}}
                  </div>
                </template>

              </v-client-table>
            </v-col>
          </v-row>
        </v-container>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal_detalle=false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>


<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';

export default {
  components: {
    "PaginacionComponent": PaginacionComponent
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    }
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('s.inventarios.cargar')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.sucursal_activa = this.$local_storage.get("sb_sucursal","0");
    if(this.sucursal_activa == "0"){ //Si en otra pestaña cambiaron a sucursal TODAS recargamos la pagina
        this.$router.go();            
    }
  },
  data() {
    return {
        sucursal_activa:"",
        model:{},
        file_adjunto: null,
        articulos_all:[],
        encabezadoLayoutInventario: {
            codigo_barras: "codigo_barras",
            existencia: "existencia",
            precio_compra: "precio_compra",
        },
        camposLayoutInventario: [{
            codigo_barras: "[Código de Barras del artículo]",
            existencia: "[Existencia. Debe ser una cantidad válida]",
            precio_compra: "[Precio de Compra con Impuestos mayor a cero. Es Opcional, si no se incluye se pondrá el precio del catálogo de artículos]",
        }],
        camposObligatoriosInventario: [
            "codigo_barras",
            "existencia",
        ],
      modal_detalle: false,
      lote_detalle: [],
      model_filters: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      fab: [],
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Inventario",
          disabled: true,
          href: ""
        },
        {
          text: "Cagar Inventario",
          disabled: true,
          href: ""
        }
      ],
      columns: [
        "fecha",
        "nombre_usuario",
        "archivo",
        "estatus",
        "errores",
        "actions"
      ],
      options: {
        filterable: ["fecha","nombre_usuario","archivo","estatus","errores"],
        sortable: ["fecha","nombre_usuario","archivo","estatus","errores"],

        headings: {
          fecha: "Fecha",
          nombre_usuario: "Usuario",
          archivo:"Archivo cargado",
          estatus: "Estatus",
          actions: "Archivo"
        },
      },
      columns_detalle: ["id_sucursal", "cantidad", "precio_compra", "fecha_caducidad", "fecha_entrada", "actual", "comprometido"],
      options_detalle: {
        filterable: ["id_sucursal", "cantidad", "precio_compra", "actual", "comprometido"],
        sortable: ["id_sucursal", "cantidad", "precio_compra", "actual", "comprometido"],

        headings: {
          id_sucursal: "Sucursal",
          cantidad: "Cantidad",
          precio_compra: "Precio de Compra",
          fecha_caducidad: "Fecha de Caducidad",
          fecha_entrada: "Fecha de Entrada",
          actual: "Actual",
          comprometido: "Comprometido"
        },
      },
      modal: false,
      items: []
    }
  },
  methods: {
    infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {"type":"carga_inventario", "id_sucursal":this.sucursal_activa };

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          where[x] = {
            "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          }
        }
      });

      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where,
            sort: [{"fecha":"desc"}],
            fields: ["_id","_rev","usuario","nombre_usuario","fecha","archivo","estatus","errores"],
            use_index:"_design/dac0a72ae20be1636478bfdaec7f5788588e078b"
          },
        }).then(async response => {
          if (response.data.docs.length) {
              for(var k in response.data.docs){
                  response.data.docs[k].fecha = window.moment(response.data.docs[k].fecha).format("YYYY-MM-DD HH:mm");
              }
            var unique_records = new Set(this.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());            
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            this.items = this.items.concat(nuevos);            
            this.props_paginacion.total_registros = this.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },


    beforeUpload(file) {
        var self = this;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (isLt2M) {
        self.file_adjunto = file;
        return true;
      }
      this.$swal({
        type: "warning",
        title: "¡Operación no Permitida!",
        text: "El archivo no debe ser mayor a 2MB.",
        footer: ""
      });
      self.file_adjunto = null;
      return false;
    },
    get_sucursal: function(id) {
      let seleccionada = window.sucursales.filter(x => x._id == id);

      return seleccionada.length > 0 ? seleccionada[0].nombre : "No Encontrada";
    },
    verificaObligatorios: function(results, camposObligatorios) {
      var errores = '';
      var keys = [];
      for (var k in results) {
        keys.push(k);
      }

      camposObligatorios.forEach(function(campo) {
        if (keys.indexOf(campo) == -1)
          errores += '<br/>' + "- El campo '" + campo + "' es obligatorio.";
      });

      return errores == '' ? true : errores;
    },
    getArticulosAll: function() {
      var self = this;
      return new Promise(function(resolve, reject) {

        let data = {
            "selector": {"type":"articulos"},
            "fields": [
                "_id","codigo_barras", "nombre","precio_compra_con_iva"
            ]
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
            if (response.data.docs.length > 0) {
              self.articulos_all = response.data.docs;
              console.log("TOTAL: ", response.data.docs.length);
            } else
              self.articulos_all = [];
            return resolve(true);
          })
          .catch(error => {
            return reject(error);
          });
      });
    },
    validaArticuloImport: function(val) {
      var filter = this.articulos_all.find(function(e){
          return  e.codigo_barras.includes(val.toString().toUpperCase().trim());
        }
      );
      if (filter)
        return filter;
      else
        return "";
    },
    validaCargaLayoutInventario: function(results) {
      var self = this;
      return new Promise(function(resolve, reject) {
        var errores = 0;
        var erroresText = '';
        var articulos = [];
        var precios = {};
        var errorTmp = '';
        let usuario = self.$local_storage.get("sb_usuario");
        const patternFloat = /^[-+]?[\d]*(\.{0,1}[\d]*)$/;
        const patternEntero = /^[\d]*$/;
        try {

          if (results.length == 0) {
            errores++;
            erroresText += "No hay datos en el layout. Verifique que exista información a partir de la tercera fila.";
          } else if (results.length > 20000) {
            errores++;
            erroresText += "El máximo de filas a procesar es 20000, favor de verificar.";
          } else {
            for (var k in results) {
              var obligatorios = self.verificaObligatorios(results[k], self.camposObligatoriosInventario);
              if (obligatorios !== true) {
                errores++;
                if (erroresText.indexOf(obligatorios) == -1)
                  erroresText += obligatorios;
              } else {

                //validar existencia sea float valido
                if (!patternFloat.test(results[k]['existencia'])) {
                  errores++;
                  errorTmp = '<br/>' + "- La existencia " + results[k]['existencia'] + " no es una cantidad válida.";
                  if (erroresText.indexOf(errorTmp) == -1)
                    erroresText += errorTmp;
                } else {
                    results[k]['existencia'] = parseFloat(results[k]['existencia']);
                }

                if (results[k]['precio_compra']!=null && !patternFloat.test(results[k]['precio_compra'])) {
                  errores++;
                  errorTmp = '<br/>' + "- El precio de compra " + results[k]['precio_compra'] + " no es una cantidad válida.";
                  if (erroresText.indexOf(errorTmp) == -1)
                    erroresText += errorTmp;
                } else if (results[k]['precio_compra']!=null && patternFloat.test(results[k]['precio_compra'])) {
                    results[k]['precio_compra'] = parseFloat(results[k]['precio_compra']);
                } else {
                    results[k]['precio_compra'] = "";
                }

                //Valida qué exista el codigo_barras
                if (results[k]['codigo_barras'] != null && results[k]['codigo_barras'].toString().trim() != "") {
                  var valArticulo = self.validaArticuloImport(results[k]['codigo_barras']);
                  if (valArticulo == "") {
                    //errores++;  //Se comenta para que guarde solo los articulos que si encontró y no detenga todo
                    errorTmp = '<br/>' + "- El código de barras " + results[k]['codigo_barras'] + " no se encontró en el catálogo.";
                    if (erroresText.indexOf(errorTmp) == -1)
                      erroresText += errorTmp;
                  } else {
                        var record = {"id_articulo":valArticulo["_id"], "existencia":results[k]['existencia'],
                                    "codigo_barras": results[k]['codigo_barras'].toString().trim(), "precio_excel":results[k]['precio_compra'],
                                    "precio_articulo": parseFloat(valArticulo["precio_compra_con_iva"]) };
                        articulos.push(record);
                  }

                }

              }

            }

          }

          return resolve({
            "errores": errores,
            "erroresText": erroresText,
            "articulos": articulos,
          });

        } catch (error) {
          console.log(error);
          return reject(error);
        }
      }); //promise
    },
    handleSuccessInventario: function({
      results,
      header
    }) {
        window.dialogLoader.show('Espere un momento por favor..');
        var errores = 0;
        var erroresText = '';
        var importData = {};
        var self = this;
        try {

          self.getArticulosAll().then(result => {
            self.validaCargaLayoutInventario(results).then(validaciones => {
              if (validaciones.errores > 0) {
                  window.dialogLoader.hide();
                    this.$swal({
                        type: "warning",
                        title: "¡Operación no Permitida!",
                        text: "Existen errores al cargar el archivo. Corrija y vuelva a intentarlo.",
                        footer: validaciones.erroresText,
                    });
              } else {
                window.dialogLoader.hide();
                self.$swal({
                    type: "info",
                    title: "Confirmar",
                    text: "El archivo es correcto. ¿Desea cargar el inventario a la sucursal "+this.$local_storage.get("sb_nombre_sucursal")+"?",
                    footer: "",
                    showCancelButton: true,
                    cancelButtonColor: "#d33",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Aceptar",
                    cancelButtonText: "Cancelar"
                    }).then(result => {
                        if (result.value) {
                            window.dialogLoader.show('Espere un momento por favor..');

                            //Creamos el registro de carga_inventario
                            this.model = {
                                type: "carga_inventario",
                                id_sucursal: this.sucursal_activa,
                                usuario: this.$local_storage.get("sb_usuario"),
                                nombre_usuario: this.$local_storage.get("sb_nombre_usuario"),
                                fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                                archivo: this.file_adjunto.name,
                                articulos: validaciones.articulos,
                                estatus: "En Proceso", //En Proceso, Cargado Correctamente, Cargado Con Errores
                                errores: "",
                            };

                            window.axios
                            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', this.model)
                            .then(response => {
                                this.model["_id"] = response.data.id;
                                this.model["_rev"] = response.data.rev;
                                //Se adjunta el xls y se manda llamar metodo de api apra cargar inventario
                                this.adjuntarArchivo();
                            })
                            .catch(error => {
                                window.dialogLoader.hide();
                                this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al guardar el registro.",
                                    footer: ""
                                });
                                this.items = [];
                                this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                            });

                        } else {
                            window.dialogLoader.hide();
                            this.file_adjunto = null;
                        }
                    });

              }
            }).catch(error => {
              self.$swal({
                type: "warning",
                title: "¡Operación no Permitida!",
                text: "Error al cargar el archivo",
                footer: error,
              });
              console.log(error);
              window.dialogLoader.hide();

            });
          }).catch(error => {
            window.dialogLoader.hide();
            self.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al obtener los artículos.",
              footer: ""
            });
          });


      } catch (error) {
        this.$swal({
          type: "warning",
          title: "¡Operación no Permitida!",
          text: "Error al cargar el archivo. Verifique que no existan celdas vacías.",
          footer: error.message,
        });
        window.dialogLoader.hide();
      }

    },

    adjuntarArchivo: function(){

        window.dialogLoader.show('Espere un momento por favor..');

        var name = encodeURIComponent(this.model.archivo);
        var id_model = this.model._id;
        var rev = this.model._rev;

        var url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' +id_model+'/' + name;
        if(rev != ""){ //Si trae rev es que ya se habían adjuntado archivos antes
            url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' +id_model+'/' + name+"?rev="+rev;
        }

        var self = this;
        window.axios
        .put(url, self.file_adjunto, {headers:{'Content-Type':self.file_adjunto.type}})
        .then(response => {
            //llamar metodo api que guarda el inventario
            window.axios
                .post(process.env.VUE_APP_REPORTES_URL + "/bodeguita/cargar_inventario_excel/", {"id":id_model})
                .then(response => {
                      this.$swal({
                        type: "success",
                        title: "¡Operación Exitosa!",
                        text: "El inventario se cargó correctamente.",
                        footer: ""
                    });
                })
                .catch(error => {
                  console.log(error);
                  this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al guardar el inventario. Intente nuevamente.",
                    footer: ""
                  });
                }).then(()=>{
                    window.dialogLoader.hide();
                    this.items = [];
                    this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                });

        })
        .catch(error => {
            window.axios
                .delete(process.env.VUE_APP_COUCHDB_URL+ '/' + process.env.VUE_APP_COUCHDB_DATABASE  + "/" + id_model + "?rev=" + rev)
                .then(response => {
                    this.items = [];
                    this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                });
            window.dialogLoader.hide();
            console.log("ERROR ", error);
            self.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al adjuntar el archivo. Intente nuevamente.",
                footer: ""
            });
        }).then(()=>{
            self.file_adjunto = null;
        });

    },

    descargarAdjunto: function(row){
        var name = encodeURIComponent(row.archivo);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' +row._id+'/'+name+"?rev="+row._rev;
        a.target = "_blank";
        a.click();
        a.parentNode.removeChild(a);
    },
    deleteRegistro: function(_id, _rev) {
      this.$swal({
        type: "info",
        title: "Cuidado",
        text: "¿Está seguro de eliminar el registro?",
        footer: "",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          window.dialogLoader.show('Espere un momento por favor..');
          window.axios
            .delete(process.env.VUE_APP_COUCHDB_URL+ '/' + process.env.VUE_APP_COUCHDB_DATABASE  + "/" + _id + "?rev=" + _rev)
            .then(response => {
              window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                color: 'success'
              });
              this.items = [];
              this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
              window.dialogLoader.hide();
            })
            .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                color: 'error'
              });
              window.dialogLoader.hide();
            });

        }

      });
    },
  }
}
</script>
